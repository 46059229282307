import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { ErrorHandler, NgModule, provideExperimentalZonelessChangeDetection, provideZoneChangeDetection } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule, provideClientHydration, withEventReplay } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgxDatatableModule } from '@swimlane/ngx-datatable'

import { SortablejsModule } from 'nxt-sortablejs'
import { environment } from 'environments/environment'

import { EffectsModule } from '@ngrx/effects'
import {
  FullRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule
} from '@ngrx/router-store'
import { StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'

import {
  CustomSerializer, effects, reducers
} from '@app/core/store'

import { getMetaReducers } from '@app/shared/store/metaReducers'

import { UsersModule } from '@app/users/users.module'
// FIX Imported because of New Quote Component if available in navbar
import { AppRoutingModule } from '@app/core/app-routing.module'

import { AppComponent } from '@app/core/app.component'
import { FooterComponent } from '@app/core/containers/footer.component'
import { NavigationComponent } from '@app/core/containers/navigation.component'
import { MenuComponent } from '@app/core/containers/menu.component'
import { NotFoundPageComponent } from '@app/core/containers/not-found-page'
import { FeedbackPageComponent } from './containers/feedback-page/feedback-page.component'

import { ComponentsModule } from '@app/core/components'

import { PipesModule } from '@app/shared/pipes'
import { MyCurrencyPipe } from '@app/shared/pipes/my-currency.pipe'
import { RoundPipe } from '@app/shared/pipes/round.pipe'

import {
  AccountDataService, ApiService, CoreComponentsModule, DieselService, MessagingService, ProfilesService, SelectizeService, SharedModule, SocketService, StoreUtilService, TypeService, AuthorizeNetService
} from '@app/shared'

import {
  AccountThreadService, ExpensesService, ExpirationService, FileService, HistoryLogService, NotificationService, TaskService, TaskBoardService,TaskProjectService, FillerRequestService, TemplateService, ToastHelper, FeedbackService, LoaderInterceptor
} from '@app/core/services'

import { getErrorHandler } from '@app/core/services/sentry.service' // Do not remove this line
// import { AccountFormModalComponent } from '@app/crm/components/account-form-modal-component'
import { AuthService, UserService } from '@app/users/services'


import { CompanyMessageContainerComponent } from './containers/company-message-container/company-message-container.component'

import { ExpensesModule } from '@app/expense/expenses.module'
import { ModalsModule } from '@app/modals/modals.module'
import { FromNowPipe } from '@app/shared/pipes/from-now.pipe'
import { SidebarModule } from '@app/sidebar/sidebar.module'
import { TokenInterceptor } from '@app/users/services/token.interceptor'
import { ShopModule } from '@app/shop/shop.module'
import { NgxPopperjsModule } from 'ngx-popperjs'
import { GoogleMapsModule } from '@angular/google-maps'
import { ModalService } from '@app/shared/services/open-modal.service'
import { DashboardsModule } from '@app/dashboards/dashboards.module'
import { SidebarService } from './services/sidebar.service'
import { ScheduleModule } from '@app/schedule/schedule.module'

const COMPONENTS = [
  AppComponent,
  NavigationComponent,
  FooterComponent,
  MenuComponent,
  NotFoundPageComponent,
  CompanyMessageContainerComponent,
  FeedbackPageComponent,
]
// const surl = "ws://dev-ws.dieseldisptach.com";
// 'http://localhost:8080'
// const config: SocketIoConfig = { url: surl, options: {} };
@NgModule({ declarations: COMPONENTS,
    bootstrap: [AppComponent], imports: [CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        GoogleMapsModule,
        AppRoutingModule,
        // NgProgressModule.forRoot(),
        StoreModule.forRoot({}, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
                strictStateSerializability: false,
                strictActionSerializability: false,
                strictActionWithinNgZone: false,
            },
        }),
        SidebarModule,
        ModalsModule,
        StoreModule.forFeature('core', reducers),
        EffectsModule.forRoot(effects),
        StoreRouterConnectingModule.forRoot({
            serializer: FullRouterStateSerializer,
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        NgxDatatableModule,
        FormsModule,
        ReactiveFormsModule,
        SortablejsModule.forRoot({ animation: 150 }),
        ComponentsModule,
        PipesModule,
        UsersModule,
        SharedModule,
        CoreComponentsModule,
        ScheduleModule,
        ShopModule,
        ExpensesModule,
        DashboardsModule,
        NgxPopperjsModule
        // SocketIoModule.forRoot(config) ,
    ], providers: [
        // Need to check with next angular version because right now we have a NG0505 warning with no way to fix it
        // provideClientHydration(withEventReplay()),
        // 
        ApiService,
        AuthService,
        TypeService,
        ProfilesService,
        ModalService,
        UserService,
        DieselService,
        SocketService,
        SidebarService,
        MessagingService,
        SelectizeService,
        AccountDataService,
        MyCurrencyPipe,
        FromNowPipe,
        RoundPipe,
        ToastHelper,
        FileService,
        TemplateService,
        NotificationService,
        AccountThreadService,
        HistoryLogService,
        TaskService,
        TaskBoardService,
        TaskProjectService,
        FillerRequestService,
        SidebarService,
        ExpirationService,
        ExpensesService,
        FeedbackService,
        AuthorizeNetService,
        { provide: RouterStateSerializer, useClass: CustomSerializer },
        {
            provide: USER_PROVIDED_META_REDUCERS,
            deps: [StoreUtilService],
            useFactory: getMetaReducers
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true
        },
        { provide: ErrorHandler, useFactory: getErrorHandler },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
  constructor() {
    console.log('[Module] : Loaded AppModule')
  }
}
